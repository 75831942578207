/* eslint-disable no-unused-vars */

import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ArgumentappTheme } from "./styles/ArgumentappTheme";
import { useRoutes } from "react-router";
import Router from "./routes/Router";

import "./styles/styles.css";
import "./styles/CustomClassSweetAlert2.css";
// import { cerrarSesion } from "./database/firebase";

const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

if (process.env.REACT_ENV !== "development") {
  console.log = () => {};
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
}

function App() {
  const routing = useRoutes(Router);

  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        // setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  /* This is the main component of a React application that sets up the routing and renders different
components based on the URL path. It also includes some conditional rendering based on whether the
user is logged in or not. The component is wrapped in a `ThemeProvider` and
`ColorModeContext.Provider` to provide a custom theme and color mode toggle functionality to child
components. */
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={ArgumentappTheme}>
        <CssBaseline />
        {routing}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
